require('./bootstrap');
$(function () {

    $('[data-toggle="tooltip"]').tooltip();

    $('select').select2({
        theme: 'bootstrap4',
    });

    $('#userMenuToggle').on( 'click', function(){
        $('#userMenuToggle').dropdown( 'toggle' );
    });

});
