window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

try {
    window.Popper = require('popper.js').default;
    window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

    window.$ = window.jQuery = require('jquery');
    require('overlayscrollbars');
    require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');
    window.AgGrid = require( 'ag-grid-enterprise/dist/ag-grid-enterprise' );
    window.MyCharts = require( 'chart.js/auto' );

    var Highcharts = require('highcharts');
    require('highcharts/modules/map')(Highcharts);
    window.Highcharts = Highcharts;



    //require( 'chart.js/auto');

    require( 'sweetalert');
    require( 'select2' );
    require( 'jquery-validation' );

    require('bootstrap');
    require('util');
    const flatpickr = require("flatpickr");




} catch (e) {}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
